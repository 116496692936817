import { Dispatch, SetStateAction } from "react";
import * as apiControllers from "../../controllers/graphsController";
import * as dataFunction from "./graphsFunction";
import theme from "../../themes/theme";

type stateType = {
  class: any;
  classDoughnut: any;
  explorer: any;
  guide: any;
  classPercentageComplete: any;
  classPercentageUpComing: any;
  bookings: any;
};

type loader = {
  class: boolean;
  users: boolean;
  classPercentage: boolean;
  bookings: boolean;
};

/**api call functionality for graphs page */

export const apiCallForGraphs = async (
  setState: Dispatch<SetStateAction<stateType>>,
  setIsLoading: Dispatch<SetStateAction<loader>>,
  params?: any
) => {
  await apiControllers.getGraphClassPercentData(
    (data) => {
      onApiCallSuccess(data, "classPercentage", setState, params);
      setLoaderValue(setIsLoading, "classPercentage");
    },
    (data) => onApiCallFailure(data, "classPercentage", setState),
    params
  );

  await apiControllers.getGraphClassData(
    (data) => {
      onApiCallSuccess(data, "class", setState, params);
      setLoaderValue(setIsLoading, "class");
    },
    (data) => onApiCallFailure(data, "class", setState),
    params
  );

  await apiControllers.getGraphBookingsdata(
    (data) => {
      onApiCallSuccess(data, "bookings", setState, params);
      setLoaderValue(setIsLoading, "bookings");
    },
    (data) => onApiCallFailure(data, "bookings", setState),
    params
  );
  await apiControllers.getGraphUserData(
    (data) => {
      onApiCallSuccess(data, "users", setState, params);
      setLoaderValue(setIsLoading, "users");
    },
    (data) => onApiCallFailure(data, "users", setState),
    params
  );
};

export const onApiCallSuccess = (
  data: any,
  graphType: string,
  setState: Dispatch<SetStateAction<stateType>>,
  params: any
) => {
  switch (graphType) {
    case "class":
      setState((prev) => ({
        ...prev,
        class: {
          data: data,
          eachStatusTotal: {
            published: getTotalOfEachStatus(data.publishedClasses),
            completed: getTotalOfEachStatus(data.CompletedClasses),
            cancelled: getTotalOfEachStatus(data.CancelledClasses),
            expired: getTotalOfEachStatus(data.ExpiredClasses),
            unpublished: getTotalOfEachStatus(data.UnpublishedClasses),
          },
          totalClasses: getTotalCount(
            [
              ...data.publishedClasses,
              ...data.CompletedClasses,
              ...data.CancelledClasses,
              ...data.ExpiredClasses,
            ],
            "count"
          ),
          graphValues: dataFunction.lineChartDataFunction(
            params,
            {
              d1: data.CancelledClasses,
              d3: data.publishedClasses,
              d2: data.CompletedClasses,
              d4: data.ExpiredClasses,
            },
            null,
            null,
            [
              "rgba(253, 110, 63, .2)",
              "rgba(178, 223, 100, .2)",
              "rgba(64, 175, 168, .2)",
              "rgba(2, 117, 224, .2)",
              "rgba(250, 196, 2, 0.5)",
            ],
            [
              theme.palette.orange.main,
              "#a5c967",
              theme.palette.teal.main,
              "#0275e0",
              "#fac402",
            ],
            [false, false, false, false, false],
            ["Cancelled", "Published", "Completed", "Expired", "Unpublished"]
          ),
        },
      }));
      return;

    case "classPercentage":
      setState((prev) => ({
        ...prev,
        classPercentageComplete: {
          graphValues: dataFunction.percentClassData(data.CompletedClasses, [
            "#F0F7F8",
            theme.palette.yellow.light,
          ]),
          percent: data.CompletedClasses,
        },
        classPercentageUpComing: {
          graphValues: dataFunction.percentClassData(data.expiredClasses, [
            "#F0F7F8",
            theme.palette.orange.main,
          ]),
          percent: data.expiredClasses,
        },
      }));
      return;

    case "users":
      setState((prev) => ({
        ...prev,
        explorer: {
          totalUsers: getTotalCount([...data.Explorers, ...data.Guides], "count"),
          totalExplorers: getTotalCount([...data.Explorers], "count"),
          graphValues: dataFunction.lineChartDataFunction(
            params,
            { d: data.Explorers },
            null,
            null,
            [theme.palette.teal.main]
          ),
        },
        guide: {
          totalGuides: getTotalCount([...data.Guides], "count"),
          graphValues: dataFunction.lineChartDataFunction(
            params,
            { d: data.Guides },
            null,
            null,
            [theme.palette.yellow.light]
          ),
        },
      }));
      return;

    case "bookings":
      setState((prev) => ({
        ...prev,
        bookings: {
          data: data,
          eachStatusTotal: {
            registered: getTotalOfEachStatus(data.Registered),
            cancelled: getTotalOfEachStatus(data.Cancelled),
          },
          totalBookings: getTotalCount([...data.Registered, ...data.Cancelled], "count"),
          graphValues: dataFunction.lineChartDataFunction(
            params,
            { d1: data.Cancelled, d2: data.Registered },
            null,
            null,
            ["rgba(253, 110, 63, 0.5)", "rgba(178, 223, 100, 0.5)"],
            [theme.palette.orange.main, "#a5c967"],
            [true, true],
            ["Cancelled", "Registered"]
          ),
        },
      }));
      return;
    default:
      setState((prev) => ({ ...prev }));
  }
};

export const setLoaderValue = (
  setIsLoading: Dispatch<SetStateAction<loader>>,
  loaderType: string
) => {
  setIsLoading((prev) => ({ ...prev, [loaderType]: false }));
};

export const onApiCallFailure = (
  data: any,
  graphType: string,
  setState: Dispatch<SetStateAction<stateType>>
) => {
  return;
};

const getTotalCount = (data: any, attribute: string) => {
  let totalCount = 0;
  data.forEach((value: any) => {
    totalCount = value[attribute] + totalCount;
  });
  return totalCount;
};

//get total of each status
function getTotalOfEachStatus(dataSet: any) {
  let totalCounts: number | string = 0;
  dataSet.forEach((eachObj: any) => {
    totalCounts = totalCounts + eachObj.count;
  });

  return totalCounts;
}

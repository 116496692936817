import { Box, Grid } from "@mui/material";
import * as React from "react";

import { CountBox, DataGridComponent } from "../components";
import style from "./compoundComponents.module.scss";

const CountListAndTableComponent = (props: any): JSX.Element => {
  const { pageState, setPageState, countDataList = [], tableColumns = [],onFilterChange } = props;
  enum ItemTitleEnum {
    Published = "Published Classes",
    Expired = "Expired Classes",
    Cancelled = "Cancelled Classes",
    Completed = "Completed Classes",
    Unpublished = "Unpublished Classes",
    Explorer = "Explorers",
    Guide = "Guides",
    Other = "Other Classes"
  }

  const handleCountBoxClick = (itemTitle: ItemTitleEnum) => {
    console.log("itemTitle", itemTitle);
    
    if (onFilterChange) {
      let filterData = countDataList.find((item:any)=>item.title==itemTitle)
      onFilterChange(filterData);
    }
    
  };

  return (
    <>
      <div className={style.countListHolderContainer}>
        {countDataList
        .filter((item: any) => item.title !== "Unpublished Classes") // Filter out "Unpublished Classes"
        .map((item: any, index: number) => (
          <React.Fragment key={index}>
            <CountBox item={item} onClick={() => handleCountBoxClick(item.title)} />
          </React.Fragment>
        ))}
      </div>
      <Box sx={{ marginBottom: "50px" }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <DataGridComponent
              columns={tableColumns}
              rows={pageState.filteredData ? pageState.filteredData: pageState.data  }
              rowCount={pageState.total}
              loading={pageState.isLoading}
              page={pageState.page}
              pageSize={pageState.pageSize}
              onPageChange={(page: any) => {
                setPageState({ ...pageState, page });
              }}
              onPageSizeChange={(pageSize: any) => {
                setPageState({ ...pageState, pageSize });
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export { CountListAndTableComponent };

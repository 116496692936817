export const dashboardConst = {
  DOWNLOAD_REPORT: "Download Report",
  SWITCH_TO_DATA_SUMMARY: "Switch to Data Summary",
  FILTER: "Filter",
  LOADER_CLASS: "Loading class data...",
  LOADER_USERS: "Loading users data...",
  LOADER_PERCENT_CLASS: "Loading class percentage...",
  LOADER_BOOKINGS_DATA: "Loading bookings data...",
  COMPLETED_CLASSES: "Completed Classes",
  UPCOMING_CLASSES: "Upcoming Classes (Published Classes)",
  SWITCH_TO_GRAPH: "Switch to Graph",
  LOADING_IMAGE: "Loading image, please wait...",
  LOADING_DATA: "Loading data, please wait...",
  EXPIRED_CLASSES: "Expired Classes",
};

import * as React from "react";
import { Divider, Grid, Stack, Typography, Container } from "@mui/material";
import { exportComponentAsPNG } from "react-component-export-image";

import {
  LoadingButtonComponent,
  ClassCompleteUpComingDougnutChart,
  ClassLineDoughnutChart,
  CircularProgressBar,
  BookingsLineChart,
  UsersBarChart,
  FilterComponent,
  SideDrawer,
} from "../../components";
import { dashboardConst } from "../../utils/text-constants";
import { IMAGES } from "../../assets/images";
import style from "./dashboard.module.scss";
import { apiCallForGraphs } from "../../utils/reusable-functions/graphsApiDataSetFunction";
import { getDateMode } from "../../utils/reusable-functions/graphsFunction";
import {
  getFilteredDate,
  getSelectedFilters,
} from "../../utils/reusable-functions/filterFunctions";

type GraphViewComponentProps = {
  onChangeView: (viewType: string) => void;
};

const GraphViewComponent: React.FC<GraphViewComponentProps> = ({ onChangeView }) => {
  const componentRef = React.useRef();
  const [isLoading, setIsLoading] = React.useState({
    class: true,
    users: true,
    classPercentage: true,
    bookings: true,
  });
  const [graphData, setGraphData] = React.useState({
    class: null,
    explorer: null,
    guide: null,
    classPercentageComplete: null,
    classPercentageUpComing: null,
    bookings: null,
  });
  const [openFilter, setOpenFilter] = React.useState<boolean>(false);
  const [selectedFilters, setSelectedFilters] = React.useState<any>();

  React.useEffect(() => {
    apiCallForGraphs(setGraphData, setIsLoading, {
      dateMode: selectedFilters
        ? getDateMode(
          selectedFilters?.selectedDates[0],
          selectedFilters?.selectedDates[1]
        )
        : "month",
      ...getSelectedFilters(selectedFilters),
    });
  }, [selectedFilters]);

  const handleViewChange = (event: any) => {
    onChangeView("dataSummaryView");
  };

  const handleDownloadReportClick = () => {
    exportComponentAsPNG(componentRef, { fileName: "Dashboard report" });
  };

  const handleFilterClick = () => {
    setOpenFilter(!openFilter);
  };

  const classPercentageDataObj = () => {
    return {
      first: {
        percent: `${parseFloat(graphData.classPercentageComplete.percent)?.toFixed(2)}%`,
        text: dashboardConst.COMPLETED_CLASSES,
      },
      second: {
        percent: `${parseFloat(graphData.classPercentageUpComing.percent).toFixed(2)}%`,
        text: dashboardConst.EXPIRED_CLASSES,
      },
    };
  };

  const onApplyOrCancelButtonClick = (data: any, filterData: any) => {
    if (data.id === "apply") {
      setSelectedFilters({ ...filterData });
    }
    setOpenFilter(false);
  };

  return (
    <>
      <Grid container p={1}>
        <Grid
          item
          sm={12}
          md={12}
          lg={12}
          className={style.topbarContainer}
          sx={{ ml: 2.5 }}
        >
          <Typography className={style.switchToDataSummery} onClick={handleViewChange}>
            {dashboardConst.SWITCH_TO_DATA_SUMMARY}
          </Typography>
          <Stack spacing={2} direction="row">
            <LoadingButtonComponent
              buttonLabel={dashboardConst.DOWNLOAD_REPORT}
              handleClick={handleDownloadReportClick}
              endIcon={<img src={IMAGES.downloadReportIcon} />}
            />
            <LoadingButtonComponent
              buttonLabel={dashboardConst.FILTER}
              handleClick={handleFilterClick}
              endIcon={<img src={IMAGES.filterIcon} />}
            />
          </Stack>
        </Grid>
      </Grid>
      <Divider className={style.divider} sx={{ ml: 3 }} />
      <Container ref={componentRef}>
        <Grid container spacing={5}>
          <Grid item sm={12} md={12} lg={12}>
            <Typography variant="h3" className={style.yearText}>
              {getFilteredDate(selectedFilters)}
            </Typography>
          </Grid>
          <Grid item md={12} lg={12}>
            {!isLoading.class && graphData.class && (
              <ClassLineDoughnutChart
                totalEachStatusCount={graphData.class.eachStatusTotal}
                chartData={graphData.class.graphValues}
                totalClasses={graphData.class.totalClasses}
              />
            )}
            {isLoading.class && (
              <CircularProgressBar loadername={dashboardConst.LOADER_CLASS} />
            )}
            {!isLoading.classPercentage && graphData.classPercentageComplete && (
              <ClassCompleteUpComingDougnutChart
                firstChartData={graphData.classPercentageComplete.graphValues}
                secondChartData={graphData.classPercentageUpComing.graphValues}
                percentageData={classPercentageDataObj()}
              />
            )}
            {isLoading.classPercentage && (
              <CircularProgressBar loadername={dashboardConst.LOADER_PERCENT_CLASS} />
            )}

            {!isLoading.users && graphData.explorer && graphData.guide && (
              <UsersBarChart
                explorerChartData={graphData.explorer.graphValues}
                guideChartData={graphData.guide.graphValues}
                usersCount={graphData.explorer.totalUsers}
                exlorersCount={graphData.explorer.totalExplorers}
                guideCounts={graphData.guide.totalGuides}
              />
            )}
            {isLoading.users && (
              <CircularProgressBar loadername={dashboardConst.LOADER_USERS} />
            )}
          </Grid>
          <Grid item sm={12} md={12} lg={12} sx={{ mt: "30px", mb: "30px" }}>
            {!isLoading.bookings && graphData.bookings && (
              <BookingsLineChart
                bookingsTotalCount={graphData.bookings.totalBookings}
                bookingsData={graphData.bookings.graphValues}
                totalEachStatusCount={graphData.bookings.eachStatusTotal}
              />
            )}
            {isLoading.bookings && (
              <CircularProgressBar loadername={dashboardConst.LOADER_BOOKINGS_DATA} />
            )}
          </Grid>
        </Grid>
      </Container>
      {openFilter && (
        <SideDrawer
          openState={openFilter}
          onCloseDrawer={() => setOpenFilter(!openFilter)}
        >
          <FilterComponent
            filters={selectedFilters}
            setFilters={setSelectedFilters}
            onButtonClick={(data: any, filterData: any) =>
              onApplyOrCancelButtonClick(data, filterData)
            }
          />
        </SideDrawer>
      )}
    </>
  );
};

export default GraphViewComponent;

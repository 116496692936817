import { Button } from "@mui/material";
import * as React from "react";
import { buttonStyle } from "../../buttonFunction";
import { checkStringLength } from "../../dataLengthCheck";

export const columnRedeemHeaders = () => {
  return [
    { headerName: "No.", field: "id", width: 50 },
    {
      headerName: "Gift Card Code",
      field: "giftCardCode",
      minWidth: 250,
      renderCell: (params: any) =>
        params.row.giftCardCode ? (
          <p title={params.row.giftCardCode}>{params.row.giftCardCode}</p>
        ) : "NA",
    },
    { headerName: "Booking ID", field: "bookingId", minWidth: 130 },
    { headerName: "Explorer ID", field: "userId", minWidth: 130 },
    {
      headerName: "Explorer Name",
      field: "fullName", // You can keep this as "fullName" or any placeholder since the value is custom-rendered.
      minWidth: 250,
      renderCell: (params: any) => {
        const fullName = `${params.row.firstName ?? "NA"} ${params.row.lastName ?? "NA"}`.trim();
        return (
          <p title={fullName}>
            {checkStringLength(fullName, 30)} {/* Adjust length as needed */}
          </p>
        );
      },
    },
    {
      headerName: "Explorer Email",
      field: "email",
      flex: 1,
      minWidth: 300,
      renderCell: (params: any) => (
        <p title={params.row.email}>{checkStringLength(params.row.email ?? "NA")}</p>
      ),
    },

    {
      headerName: "Date of Booking",
      field: "createdOn", // Updated field to match the data source
      minWidth: 200,
      renderCell: (params: any) => {
        const createdOn = params.row.createdOn
          ? new Date(params.row.createdOn).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })
          : "NA"; // Handle missing or invalid dates
        return (
          <p title={createdOn}>
            {createdOn}
          </p>
        );
      },
    },
    {
      headerName: "Class Id",
      field: "classId",
      minWidth: 220,
      renderCell: (params: any) =>
        params.row.classId ? (
          <p title={params.row.classId}>{params.row.classId}</p>
        ) : null,
    },
    {
      headerName: "Class Name",
      field: "classTitle",
      minWidth: 220,
      renderCell: (params: any) =>
        params.row.classTitle ? (
          <p title={params.row.classTitle}>{params.row.classTitle}</p>
        ) : null,
    },
    {
      headerName: "Guide Id",
      field: "guideId",
      minWidth: 220,
      renderCell: (params: any) =>
        params.row.guideId ? (
          <p title={params.row.guideId}>{params.row.guideId}</p>
        ) : null,
    },
    {
      headerName: "Guide Name",
      field: "guideFullName", // You can keep this as "fullName" or any placeholder since the value is custom-rendered.
      minWidth: 250,
      renderCell: (params: any) => {
        const fullName = `${params.row.guideFirstName ?? "NA"} ${params.row.guideLastName ?? "NA"}`.trim();
        return (
          <p title={fullName}>
            {checkStringLength(fullName, 30)} {/* Adjust length as needed */}
          </p>
        );
      },
    },
    {
      headerName: "Applied Amount",
      field: "appliedAmount",
      minWidth: 220,
      renderCell: (params: any) =>
        params.row.appliedAmount ? (
          <p title={params.row.appliedAmount}>{params.row.appliedAmount}</p>
        ) : null,
    },
    {
      headerName: "Remaining Balance",
      field: "remainingBalance",
      minWidth: 220,
      renderCell: (params: any) =>
        params.row.remainingBalance ? (
          <p title={params.row.remainingBalance}>{params.row.remainingBalance}</p>
        ) : null,
    },
    
  ];
};


export const columnPurchasedHeaders = () => {
  return [
    { headerName: "No.", field: "id", width: 50 },
    {
      headerName: "Gift Card Code",
      field: "giftCardCode",
      minWidth: 250,
      renderCell: (params: any) =>
        params.row.giftCardCode ? (
          <p title={params.row.giftCardCode}>{params.row.giftCardCode}</p>
        ) : null,
    },
    { headerName: "Explorer ID", field: "userId", minWidth: 130 },
    {
      headerName: "Explorer Name",
      field: "fullName", // You can keep this as "fullName" or any placeholder since the value is custom-rendered.
      minWidth: 250,
      renderCell: (params: any) => {
        const fullName = `${params.row.firstName ?? "NA"} ${params.row.lastName ?? "NA"}`.trim();
        return (
          <p title={fullName}>
            {checkStringLength(fullName, 30)} {/* Adjust length as needed */}
          </p>
        );
      },
    },
    {
      headerName: "Explorer Email",
      field: "email",
      flex: 1,
      minWidth: 300,
      renderCell: (params: any) => (
        <p title={params.row.email}>{checkStringLength(params.row.email ?? "NA")}</p>
      ),
    },
    {
      headerName: "Date of Purchase",
      field: "createdOn", // Updated field to match the data source
      minWidth: 200,
      renderCell: (params: any) => {
        const createdOn = params.row.createdOn
          ? new Date(params.row.createdOn).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })
          : "NA"; // Handle missing or invalid dates
        return (
          <p title={createdOn}>
            {createdOn}
          </p>
        );
      },
    },
    {
      headerName: "Receiver Name",
      field: "name",
      flex: 1,
      minWidth: 300,
      renderCell: (params: any) => (
        <p title={params.row.receiverName}>{checkStringLength(params.row.receiverName ?? "NA")}</p>
      ),
    },
    {
      headerName: "Receiver Email",
      field: "receiverEmail",
      flex: 1,
      minWidth: 300,
      renderCell: (params: any) => (
        <p title={params.row.receiverEmail}>{checkStringLength(params.row.receiverEmail ?? "NA")}</p>
      ),
    },
    {
      headerName: "Receiver Message",
      field: "receiverMessage",
      flex: 1,
      minWidth: 300,
      renderCell: (params: any) => (
        <p title={params.row.receiverMessage}>{checkStringLength(params.row.receiverMessage ?? "NA")}</p>
      ),
    },
    {
      headerName: "Purchased Balance",
      field: "PurchaseBalance",
      minWidth: 220,
      renderCell: (params: any) =>
        params.row.PurchaseBalance ? (
          <p title={params.row.PurchaseBalance}>{params.row.PurchaseBalance }</p>
        ) : null,
    },
    {
      headerName: "Remaining Balance",
      field: "remainingBalance",
      minWidth: 220,
      renderCell: (params: any) =>
        params.row.remainingBalance ? (
          <p title={params.row.remainingBalance}>{params.row.remainingBalance}</p>
        ) : null,
    },
  ];
};
